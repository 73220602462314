import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';

import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';

import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { SparklineAllModule } from '@syncfusion/ej2-angular-charts';

import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import {  MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';

import { NumericTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';

import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ContextMenuModule } from '@syncfusion/ej2-angular-navigations';
import { ContextMenuService, EditService, ExcelExportService, FilterService, PageService, PdfExportService, ResizeService, SortService, ToolbarService, TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import {ButtonModule} from '@syncfusion/ej2-angular-buttons';

import { GridAllModule,FreezeService,SelectionService  } from '@syncfusion/ej2-angular-grids';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CheckBoxModule,
    DropDownListAllModule,
    DatePickerAllModule,
    ToolbarModule,MultiSelectAllModule,
    NumericTextBoxAllModule,
    DialogModule,
    GridAllModule,
    ContextMenuModule,
    TreeGridModule,
    ButtonModule,
    SparklineAllModule
  ],
  providers: [PageService,
    SortService,
    FilterService,
    EditService,
    SortService, ResizeService,
    ExcelExportService,
    PdfExportService, ContextMenuService,
    ToolbarService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
