import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { sampleData } from './data';
import { TreeGridComponent, EditSettingsModel, ToolbarItems, ToolbarService, EditService, SortSettingsModel, PageSettingsModel, ContextMenuService, ExcelExportService, FilterService, PageService, PdfExportService, ResizeService, SortService, ColumnChooserService } from '@syncfusion/ej2-angular-treegrid';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { MenuEventArgs, MenuItemModel } from '@syncfusion/ej2-navigations';
// import { sampleData  } from './dataSource';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GridComponent, parentsUntil } from '@syncfusion/ej2-angular-grids';
import { ContextMenuComponent } from '@syncfusion/ej2-angular-navigations';

import { ChangeEventArgs, DropDownList, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [SortService, ColumnChooserService,ResizeService,ToolbarService,EditService, PageService, FilterService, EditService, ExcelExportService, PdfExportService, ContextMenuService]
})
export class AppComponent implements OnInit {



  public data: Object[];
  @ViewChild('grid') public grid: GridComponent;
  @ViewChild('grid1') public gridSecond: GridComponent;
  @ViewChild('contextmenu')
  public contextmenu: ContextMenuComponent;
  @ViewChild('headercontextmenu')
  public headercontextmenu: ContextMenuComponent;
  @ViewChild('treegrid')
  public treegrid: TreeGridComponent;
  @ViewChild('dropdown1')
  public dropdown1: DropDownListComponent;
  public toolbar: string[];
  public pageSettings: PageSettingsModel;
  public selectitem: string[];
  public filterOptions: Object = { type: 'Menu' };



  public filterSettings: Object;
  public templateOptions: object;
  public dropDownFilter: DropDownList;
  public d1data: Object;
  public fields1: Object;
  public selectionSettings: Object;
  public menuItems: MenuItemModel[] = [
    {
      text: 'AddNext'
    },
    {
      text: 'AddChild'
    },
    {
      text: 'Del'
    },
    {
      text: 'Edit'
    },
    {
      text: 'Copy'
    }];
  public headermenuItems: MenuItemModel[] = [
    {
      text: 'Style',
      id: 'Style',
      items: [
        {
          text: 'Column background color',

        },
        {
          text: 'Font-size',

        },
        {
          text: 'Font-color',

        },
        {
          text: 'Alignment',

        },
        {
          text: 'Text-wrap',

        }
      ]
    },
    {
      text: 'Freeze',
      id: 'Freeze'
    },

  ];

  public contextMenuItems: any;
  public editing: EditSettingsModel = { allowEditing: true, allowDeleting: true };
  beforeOpen(args): void {
    this.headercontextmenu.showItems(['Style', 'Choose', 'Freeze', 'Filter', 'MultiSort']);
    if (parentsUntil(args.event.target, 'first', true)) {
      if (this.grid.getColumnByField('ShipCountry').visible == true) {
        this.headercontextmenu.hideItems(['UnHide Column', 'Sort Column', 'Clear Sort']);
      }
      else {
        this.headercontextmenu.hideItems(['Hide Column', 'Sort Column', 'Clear Sort']);
        this.headercontextmenu.showItems(['UnHide Column']);
      }
    } else {
      this.headercontextmenu.hideItems(['UnHide Column', 'Hide Column']);
      this.headercontextmenu.showItems(['Sort Column', 'Clear Sort']);
    }

  }
  select(args): void {
    this.selectitem = args.item.text;
    if (args.item.text === "Save Grid Changes") {
      this.grid.editModule.batchSave();
    }
    if (args.item.text === 'Print Preview') {
      this.grid.print();
    }
    if (args.item.text === 'Show Summary') {
      this.grid.aggregates = [{
        columns: [{
          type: 'Sum',
          field: 'Freight',
          footerTemplate: 'Sum: ${Sum}'
        }]
      }]
    }
    if (args.item.text === 'Hide Column') {
      this.grid.getColumnByField('ShipCountry').visible = false;
      this.grid.refreshColumns();
    }
    if (args.item.text === 'UnHide Column') {
      this.grid.getColumnByField('ShipCountry').visible = true;
      this.grid.refreshColumns();
    }
    if (args.item.text === 'Column background color') {
      (document.getElementsByClassName('e-headercell') as any).style = "background-color: green";
    }
    if (args.item.text === 'Reset color') {
      (document.getElementsByClassName('e-headercell') as any).style.cssText = "";
    }
    if (args.item.text === 'Copy') {
      this.treegrid.copy();
      // (document.getElementsByClassName('e-headercell') as any).style.cssText = "";
    }
    if (args.item.text === 'Sort Column') {
      this.gridSecond.sortColumn('CustomerName', 'Ascending');
    }
    if (args.item.text === 'Clear Sort') {
      this.gridSecond.clearSorting();
    }
  }

  ngOnInit(): void {
    this.selectionSettings = { type: 'Multiple',mode:'Row' };
    // this.treegrid.selectionSettings.type = 'Multiple';
    // this.treegrid.selectionSettings.mode = 'Row';
    this.toolbar = ['ColumnChooser'];
    // this.toolbar = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
    this.data = sampleData;
    this.editing = { allowDeleting: true, allowEditing: true, mode: 'Batch' };
    this.pageSettings = { pageSize: 15 };
    this.filterSettings = { type: 'FilterBar', hierarchyMode: 'Parent', mode: 'Immediate' };
        this.templateOptions = {
            create: (args: { element: Element }) => {
                let dd: HTMLInputElement = document.createElement('input');
                dd.id = 'duration';
                return dd;
            },
            write: (args: { element: Element }) => {
                let dataSource: string[] = ['All', '1', '3', '4', '5', '6', '8', '9'];
                this.dropDownFilter = new DropDownList({
                    dataSource: dataSource,
                    value: 'All',
                    change: (e: ChangeEventArgs) => {
                        let valuenum: any = +e.value;
                        let id: any = <string>this.dropDownFilter.element.id;
                        let value: any = <string>e.value;
                        if ( value !== 'All') {
                            this.treegrid.filterByColumn( id, 'equal', valuenum );
                        } else {
                            this.treegrid.removeFilteredColsByField(id);
                        }
                    }
                });
                this.dropDownFilter.appendTo('#duration');
         }
        };
        this.fields1 = { text: 'mode' , value: 'id'};
        this.d1data= [{ id: 'Parent', mode: 'Parent' },
                      { id: 'Child', mode: 'Child' },
                      { id: 'Both', mode: 'Both' },
                      { id: 'None', mode: 'None' },]
  }

  change (e: ChangeEventArgs) : void {
    let mode: any = <string>e.value;
    this.treegrid.filterSettings.hierarchyMode = mode;
    this.treegrid.clearFiltering();
    this.dropDownFilter.value = 'All';
}

  public visible: Boolean = false;

  public position: any = { X: 100, Y: 100 };

  public alertDlgButtons: Object[] = [{
    buttonModel: {
      isPrimary: true,
      content: 'Submit',
      cssClass: 'e-flat',
    },
    click: function () {
      this.hide();
    }
  }];

  public itemSelect(args: MenuEventArgs): void {
    if (args.item.text === "Back") {
      console.log("spiderMan");
    }
  }



  actionBegin(args) {
    if (args.requestType === "sorting") {
      console.log(args);
      // here we can catch the event while clicking the headercell
      alert(args.columnName + ':' + args.direction);
    }
  }

  rowSelected(args) {
    console.log("haha", args)
}
};

