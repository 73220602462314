<div id="action-description">

</div>


<ejs-dialog #dialog [buttons]='alertDlgButtons' [visible]='visible' content='This file can be saved as PDF' width='200px' height='110px' [position]='position'>
</ejs-dialog>
<ejs-treegrid [dataSource]='data' allowSelection='true' [selectionSettings]='selectionSettings' (rowSelected)='rowSelected($event)' allowFiltering="true" allowPaging="true" allowSorting="true" [contextMenuItems]="contextMenuItems" [editSettings]="editing"
    [filterSettings]="filterSettings" [editSettings]='editing' [pageSettings]='pageSettings' #treegrid [contextMenuItems]='contextMenuItems' [showColumnChooser]='true' [allowResizing]='true' [toolbar]="toolbar" [treeColumnIndex]='1' childMapping='subtasks'
    [allowExcelExport]='true'>
    <e-columns>

        <e-column field='taskID' headerText='Task ID' textAlign='Right' width=90></e-column>
        <e-column field='taskName' headerText='Task Name' textAlign='Left' width=180></e-column>
        <e-column field='startDate' headerText='Start Date' textAlign='Right' format='yMd' width=120></e-column>
        <e-column field='duration' headerText='Duration' textAlign='Right' width=110></e-column>
    </e-columns>

</ejs-treegrid>


<div>
    <ejs-contextmenu #contextmenu target=".e-content" [items]="menuItems" (select)="select($event)"></ejs-contextmenu>
</div>

<div>
    <ejs-contextmenu #headercontextmenu target=".e-gridheader" [items]="headermenuItems" (beforeOpen)="beforeOpen($event)" (select)="select($event)"></ejs-contextmenu>
</div>
<router-outlet>
</router-outlet>